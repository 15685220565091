.document-container {
    max-height: 500px; /* Adjust the height as needed */
    overflow-y: auto;
    border: 2px solid #109f0e; /* Example: Blue border */
    box-shadow: 0px 0px 10px #1c261f; /* Example: Shadow for depth */
    margin: 10px 0;
    padding: 10px;
    background-color: #000000; /* Optional, for styling */
    flex-basis: 395px; /* Example: sets a base width for each document container */
    max-width: 100%; /* To ensure it doesn't overflow the viewport */
    overflow-x: auto; /* Enable horizontal scrolling within each document if needed */
}

.documents-flex-container {
    display: flex;
    flex-direction: row;
    gap: 10px; /* Spacing between documents */
    margin-bottom: 20px; /* Spacing below the document row */
    font: 12px/1.5 Times New Roman, serif; /* Example: Font styling */
}
